.timeline--wrapper {
    width: calc(100% - 24px);
    padding: 5px;
  }
.timeline {
    width: 100%;
    max-width: 800px;
    padding: 0px 0 0  0;
    position: relative;
    /* box-shadow: 0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2); */
    margin-top: 15px;
    margin-bottom: 0px;
  }
.timeline:before {
    content: "";
    position: absolute;
    top: 0px;
    left: calc(27% - 3px);
    bottom: 10px;
    width: 0px;
    border: 2px solid;
  }
.timeline:after {
    content: "";
    display: table;
    clear: both;
  }
.body-container {
    position: relative;
    left: -37px;
    margin-bottom: 0px
  }
.timeline-item--no-children .body-container {
    background: transparent;
    box-shadow: none;
  }
.body-container:after {
    content: "";
    display: table;
    clear: both;
  }
.timeline-item-date {
    position: absolute;
    top: -12px;
    left: 0px;
    background: #ddd;
    padding: 1px;
    -webkit-clip-path: polygon(0% 0%, 95% 0%, 100% 50%, 95% 100%, 0% 100%);
    clip-path: polygon(0% 20%, 95% 20%, 100% 50%, 95% 85%, 0% 85%);
    height: 52px;  
    box-sizing: border-box;
    width: 70%;
  }
.timeline-item-dateinner {
    background: #e86971;
    color: #fff;
    padding: 0;
    font-size: 60%;
    font-weight: bold;
    margin: 0;
    border-right-color: transparent;
    -webkit-clip-path: polygon(0% 0%, 95% 0%, 100% 50%, 95% 100%, 0% 100%);
    clip-path: polygon(0% 0%, 95% 0%, 100% 50%, 95% 100%, 0% 100%);
    height: 50px;
    width: 100%;
    display: block;
    line-height: 52px;
    text-indent: 15px;
  }
  
.timeline-item--no-children .body-container::before {
    display: none;
  }
.entry {
    clear: both;
    text-align: left;
    position: relative;
  }
.timeline--animate .entry .is-hidden {
    visibility: hidden;
  }
.timeline--animate .entry .bounce-in {
    visibility: visible;
    -webkit-animation: bounce-in 0.4s;
    -moz-animation: bounce-in 0.4s;
    animation: bounce-in 0.4s;
  }
.entry .title {
    margin-bottom: 0.5em;
    float: left;
    width: 34%;
    position: relative;
    height: 32px;
  }
.entry .title:before {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    border: 4px solid;
    background-color: #ffffff;
    border-radius: 100%;
    top: 15%;
    right: calc(16% - 1px);
    z-index: 99;
    box-sizing: content-box;
    color: #00aac1;
  }
.entry .body {
    margin: 0 0 3em;
    float: right;
    width: 66%;
    color: #333;
    margin-bottom: 25px;
  }
.entry .body p {
    line-height: 1.4em;
  }
.entry .body h1, .entry .body h2, .entry .body h3, .entry .body h4, .entry .body h5, .entry .body h6 {
    margin: 0;
  }
.entry .body h5{
  font-size: 0.8em
}
.entry .body h4{
  font-size: 1.1em
}
.entry .body p:first-child {
    margin-top: 0;
    font-weight: 400;
  }
  @-o-keyframes bounce-in {
    0% {
      opacity: 0;
      -webkit-transform: scale(0.5);
    }
  
    100% {
      -webkit-transform: scale(1);
    }
  }
  
  @-webkit-keyframes bounce-in {
    0% {
      opacity: 0;
      -webkit-transform: scale(0.5);
    }
  
    100% {
      -webkit-transform: scale(1);
    }
  }
  @-moz-keyframes bounce-in {
    0% {
      opacity: 0;
      -moz-transform: scale(0.5);
    }
  
    100% {
      -moz-transform: scale(1);
    }
  }
  @keyframes bounce-in {
    0% {
      opacity: 0;
      -webkit-transform: scale(0.5);
      -moz-transform: scale(0.5);
      -ms-transform: scale(0.5);
      -o-transform: scale(0.5);
      transform: scale(0.5);
    }
  
    100% {
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
    }
  }
  @media only screen and (width: 1366px) {
    /* for ipad pro vertical;: */
    .timeline:before {
      left: calc(31%);
    }
    .entry .body {
      margin: 0 0 3em;
      float: right;
      width: 63%;
      color: #333;
      margin-bottom: 25px;
    } 
  .entry .title:before {
    top: 0%;
    right: 1%;
    z-index: 99;
  }
  .timeline-item-date {
    position: absolute;
    top: -15px;
    left: 0px;
    background: #ddd;
    padding: 1px;
    -webkit-clip-path: polygon(0% 20%, 95% 20%, 100% 50%, 95% 80%, 0% 80%);
    clip-path: polygon(0% 20%, 95% 20%, 100% 50%, 95% 80%, 0% 80%);
    height: 52px;  
    box-sizing: border-box;
    width: 80%;
  }
.timeline-item-dateinner {
    background: #e86971;
    color: #fff;
    padding: 0;
    font-size: 'auto';
    font-weight: bold;
    margin: 0;
    border-right-color: transparent;
    -webkit-clip-path: polygon(0% 0%, 95% 0%, 100% 50%, 95% 100%, 0% 100%);
    clip-path: polygon(0% 0%, 95% 0%, 100% 50%, 95% 100%, 0% 100%);
    height: 50px;
    width: 100%;
    display: block;
    line-height: 52px;
    text-indent: 15px;
  }
}
@media only screen and (max-width: 960px) {
  /* For small screens*/
  .timeline:before {
    left: calc(1% + 3px);
  }
  .entry .title {
    float: left;
    width: 70%;
    margin-top: 10px;
    margin-bottom: 0px;
  }
  .timeline-item-date {
    margin-left: 30px;
    top: -11px;
    left: 0px;
    background: #ddd;
    padding: 1px;
    -webkit-clip-path: polygon(0% 0%, 75% 0%, 80% 34%, 75% 64%, 0% 64%);
    clip-path: polygon(0% 0%, 75% 0%, 80% 34%, 75% 64%, 0% 64%);
    height: 52px;  
    box-sizing: border-box;
    width: 45%
  }
  .timeline-item-dateinner {
    background: #e86971;
    color: #fff;
    padding: 0;
    font-size: 1.2ex;
    font-weight: bold;
    margin: 0;
    border-right-color: transparent;
    -webkit-clip-path: polygon(0% 0%, 75% 0%, 80% 34%, 75% 64%, 0% 64%);
    clip-path: polygon(0% 0%, 75% 0%, 80% 34%, 75% 64%, 0% 64%);
    height: 50px;
    width: 100%;
    display: block;
    line-height: 33px;
    text-indent: 4px;
  }
  .entry .title:before {
    top: 0%;
    left: 3px;
    right: auto;
    z-index: 99;
  }
  .entry .body {
    margin: 5px 0 3em;
    float: right;
    width: 90%;
    margin-bottom: 15px;
  }
  .body-container {
  position: relative;
  left: -30px;
  }
  .entry .body h5{
    font-size: 0.8em
  }
  .entry .body h4{
    font-size: 1.2em
  }
  }
  @media only screen and (max-width: 600px) {
    /* For extra small screens: */
    .timeline-item-date {
      margin-left: 30px;
      top: -11px;
      left: 0px;
      background: #ddd;
      padding: 1px;
      -webkit-clip-path: polygon(0% 0%, 90% 0%, 100% 34%, 90% 64%, 0% 64%);
      clip-path: polygon(0% 0%, 90% 0%, 100% 34%, 90% 64%, 0% 64%);
      height: 52px;  
      box-sizing: border-box;
      width: 70%;
    }
  .timeline-item-dateinner {
    background: #e86971;
    color: #fff;
    padding: 0;
    font-size: auto;
    font-weight: bold;
    margin: 0;
    border-right-color: transparent;
    -webkit-clip-path: polygon(0% 0%, 90% 0%, 100% 34%, 90% 64%, 0% 64%);
    clip-path: polygon(0% 0%, 90% 0%, 100% 34%, 90% 64%, 0% 64%);
    height: 50px;
    width: 100%;
    display: block;
    line-height: 33px;
    text-indent: 4px;
  }
  .body-container {
    position: relative;
    left: -30px;
    width: 90%;
    margin-right: 0px
    }
  .timeline:before {
    left: calc(1% + 6px);
  }
  .entry .body h5{
    font-size: 0.6em
  }
  .entry .body h4{
    font-size: 1em
  }
  }
  @media only screen and (max-width: 374px) {
    /* For mobile phones with screen width under 374px: */
    .timeline-item-date {
      width: 85%;
    }
  }


  