.mapboxgl-popup {
    max-width: 200px;
}

.mapboxgl-popup-content {
    text-align: center;
    font-family: 'Open Sans', sans-serif;
} .dark .mapboxgl-popup-content {
    background: black !important;
}
.dark .mapboxgl-popup-tip {
    border-bottom-color: black !important;
    border-top-color: black !important;
}

.key{
  margin-left: 0px;
}

.key {
    display: inline-block;
    overflow-wrap: break-word;
}

.key img {
    width: 20px;
    height: auto;
    padding-right: 0.3rem;
}

.key p {
    padding-right: 1rem;
    display: inline;
}

@media (max-width: 700px) {
    .mapboxgl-ctrl-zoom-in, .mapboxgl-ctrl-zoom-out, .mapboxgl-ctrl-compass {
        display: none !important;
    }
}

.mapboxgl-map {
    position: relative;
    top: 0;
    bottom: 0;
    width: 100%;
    font-size: 1em;
    font-family: inherit;
}

.mapboxgl-popup {
    opacity: 0.95;
    z-index: 501;
}

.mapboxgl-popup-content {
    box-shadow: -1px 0px 16px -4px rgba(189,189,189,1);
}

div.map-cont-controls {

    pointer-events: none;
    white-space: nowrap;
}

.map-slider-container {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: stretch
}
.map-slider-item {
  flex: 0 1 auto;
  margin: 10px;
}

/*@media only screen and (max-width: 600px) {*/
    div.map-cont-controls {
        padding: 4px 0 4px 0;
    }
/*}*/


div.map-cont-controls {
    padding: 4px 6px 0 6px;
    position: absolute;
    top: 8px;
    left: 8px;
    z-index: 500;
    width: 30%;
    min-width: 245px;
    border-radius: 6px;
    box-shadow: -1px 0px 16px -4px rgba(189,189,189,1);
} .light div.map-cont-controls {
    background: white;
} .dark div.map-cont-controls {
    background: black;
}


.rainbow-text {
    /* https://w3bits.com/rainbow-text/ */
    /* rgb(157, 113, 234), #00c177, #ff603c, #c11700  */
    background-image: linear-gradient(to left, #bd7171, #0075bf);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

code {
    background-color: lightyellow;
    padding: 2px;
    color: black;
    font-size: 1em;
    font-family: monospace;
    border: 1px dashed #ddd;
    white-space: nowrap;
}



.loading-cont {
    /* needs to be fixed width to be centered! */
    width: 176px;

    position: absolute;
    left: 50%;
    margin-left: -88px;
    top: 50%;
    margin-top: -50px;
    pointer-events: none;
    box-sizing: border-box;
    box-shadow: -1px 0px 16px -4px rgba(189,189,189,1);
}
.loading-cont-shown {
    opacity: 1;
    transition: opacity 0.7s;
    transition-timing-function: cubic-bezier(1,0,1,0);
}
.loading-cont-hidden {
    opacity: 0;
}

.loading-pad {
    width: 176px;
    padding: 10px 13px;
    box-sizing: border-box;
} .light .loading-cont {
    color: black;
    background-color: white;
} .dark .loading-cont {
    color: white;
    background-color: black;
}

.loading-pad-cont {
    position: relative;
}

.loading-indicator-bg {
    background-color: gray;
}
.loading-loaded {
    background-color: darkred;
    height: 15px;
}


.narrow-text {
    display: inline-block;
    transform: scale(1.4,2);
    margin-left: 13.5%;
    margin-right: 13%;
    font-size: 53%;
    vertical-align: middle;
}

