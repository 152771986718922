body {
  background-color: #f2f4f4;
  overflow-x: hidden;

  margin: 0;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 1em !important;
  line-height: 1.4em !important;
}
@media only screen and (max-width: 900px) {
  body {
    font-size: 0.95em !important;
  }
}
@media only screen and (max-width: 600px) {
  body {
    font-size: 0.85em !important;
  }
}

h1, h2, h3, h4, h5 {
    font-family: 'Lato','Helvetica Neue',Arial,Helvetica,sans-serif;
    line-height: 1.28571429em;
    font-weight: 400;
}

h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child {
    margin-top: 0;
}
/* End from semantic UI */







.App {
  text-align: center;
  background-color: #111;
}

header i {
  color: #eee;
}

.due {
  font-size: 0.9rem;
  color: #888;
  font-weight: 400;
  padding-left: 1rem;
  padding-top: 0.7rem;
}

.title {
  font-size: 1.2rem;
  font-weight: 600;
  color: #f60;
  margin: 0.5rem 0;
  display: block;
}

.new {
  padding: 1rem 0;
  border-bottom: 1px solid #efefef;
}

.new-date {
  color: #888;
}

.new-date > .relative {
  color: #000;
  font-weight: 600;
  display: inline-block;
  padding-right: 1rem;
}

.flightInfo,
.province {
  display: flex;
  text-align: center;
  font-size: 90%;
}

.flightInfo .province.header > div {
  color: #000000;
}

.light .table-footer > div {
  background: #e2e8ec;
}

.area,
.confirmed,
.death,
.cured,
.activeCase,
.flightArea,
.tested {
  display: flex;
  justify-content: center;
  align-items: center;
} .light .area,
.light .confirmed,
.light .death,
.light .cured,
.light .activeCase,
.light .flightArea,
.light .tested {
  border: 1px solid #fff;
  background-color: #f8f8f8;
} .dark .area,
.dark .confirmed,
.dark .death,
.dark .cured,
.dark .activeCase,
.dark .flightArea,
.dark .tested {
  border: 1px solid #000;
  background-color: #151515;
}

.area {
  flex: 0.8;
  padding: 0.4rem 0;
  position: relative;
  font-size: 0.68rem;
} .light .area {
  color: #212f3d;
} .dark .area {
  color: #b0c1ef;
}

.flightArea {
  font-size: 90%;
  flex: 3;
  padding: 0.4rem 0;
  position: relative;
} .light .flightArea {
  color: #212f3d;
} .dark .flightArea {
  color: #b0c1ef;
}

.area.active::before {
  content: ".";
  display: block;
  position: absolute;
  font-size: 0;
  line-height: 0;
  width: 0;
  height: 0;
  border-width: 6px 0 6px 6px;
  border-style: solid;
  border-color: transparent transparent transparent #f60;
  left: 10px;
  top: 50%;
  transform: translate(0, -50%);
}

.area.header {
  background-color: #bae1ff;
  filter: saturate(65%);
  font-size: 90%;
} .light .area.header {
  color: #bae1ff;
} .dark .area.header {
  color: #212f3d;
}

.confirmed,
.death,
.cured,
.activeCase,
.tested {
  padding: 0.3rem 0;
  flex: 1;
  width: 0;
  font-size: 95%;
}

.confirmed {
  color: #ff603c;
  flex: 1.4;
}

.confirmed.header {
  background-color: #bae1ff;
  filter: saturate(65%);
  /*font-size: 80%;*/
}

.confirmedtitle {
  color: #0d0c0d;
  font-size: 90%;
  font-weight: 500;
}

.activeCase {
  color: #f75c8d;
  flex: 1.1;
}
.activeCase.header {
  background-color: #bae1ff;
  filter: saturate(65%);
  font-size: 80%;
}

.activetitle {
  color: #002a00;
  font-size: 90%;
  font-weight: 500;
}

.cured {
  /*color: #00c177;*/
  color: #4ac100;
  flex: 1.3;
}

.cured.header {
  background-color: #bae1ff;
  filter: saturate(65%);
  font-size: 80%;
}

.recoveredtitle {
  color: #0d0c0d;
  font-size: 90%;
  font-weight: 500;
}

.tested {
  color: #007cf2;
  flex: 0.7;
}

.tested.header {
  background-color: #bae1ff;
  filter: saturate(65%);
  font-size: 80%;
}

.testedtitle {
  color: #0d0c0d;
  font-size: 90%;
  font-weight: 500;
}

.death {
  color: #c11700;
  flex: 0.8;
}

.death.header {
  background-color: #bae1ff;
  filter: saturate(65%);
  font-size: 80%;
}

.deathtitle {
  color: #0d0c0d;
  font-size: 90%;
  font-weight: 500;
}

.statetitle {
  color: #0d0c0d;
  font-size: 100%;
  font-weight: 500;
}

.fallback {
  padding: 0 1rem 1rem;
  text-align: center;
  font-size: 70%;
} .light .fallback {
  color: black;
  background-color: #bae1ff;
} .dark .fallback {
  color: white;
  background-color: #000615;
}

a {
  text-decoration: none;
  color: unset;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}

.info a {
  color: #fff;
  cursor: pointer;
  line-height: 1.6em;
  font-size: 1.5rem;
  font-weight: 600;
}

.info > li {
  list-style: none;
  background-color: #2834b9;
  padding: 0.8rem 1.8rem;
  border-radius: 8px;
  text-decoration: none;
  margin: 0.5rem 0;
  background-image: url(https://assets.dxycdn.com/gitrepo/bbs-mobile/dist/static/bg-image-3-2@2x.b56753cd.png);
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: auto 3rem;
}

.tip {
  color: #888;
  padding: 0.5rem 0;
  /* border-left: 1px solid #f60; */
}

h2 small {
  color: #f60;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.more {
  font-size: 70%;
}

.mapPopup {
  background: white;
  color: #3f618c;
  font-weight: 400;
  padding: 5px;
  border-radius: 2px;
}

.imageContainer {
  width: 480px;
  height: 675px;
}

.formatImage {
  width: 100%;
}

.centerMedia {
  margin: 2rem 1rem;
  justify-content: center;
  text-align: center;
}

.formatTable {
  color: black;
}

@media (max-width: 700px) {
  .formatMedia {
    width: 426px !important;
    height: 240px !important;
    justify-content: center;
  }

  .formatTable {
    font-size: small;
  }

  .imageContainer {
    width: 360px;
    height: 506px;
  }
}

@media (max-width: 440px) {
  .formatMedia {
    width: 319px !important;
    height: 180px !important;
    justify-content: center;
  }

  .responsiveH2 {
    font-size: large;
    font-weight: 800;
  }

  .responsiveH3 {
    font-size: medium;
    font-weight: 800;
  }

  .responsiveText {
    font-size: small;
  }

  .pagination {
    font-size: xx-small;
  }

  .formatTable {
    font-size: x-small;
  }

  .imageContainer {
    width: 240px;
    height: 337px;
  }

  .mediaText {
    font-size: x-small;
  }
}

@media (max-width: 330px) {
  .formatMedia {
    width: 284px !important;
    height: 160px !important;
    justify-content: center;
  }

  .formatTable {
    font-size: xx-small;
  }

  .imageContainer {
    width: 160px;
    height: 225px;
  }

  .mediaText {
    font-size: xx-small;
  }
}

.removePadding {
  padding: 0 !important;
}

.navBar {
  height: 40px;
  justify-content: space-around;
  align-items: center;

}
.navBarStuck {
  height: 40px;
  justify-content: space-around;
  align-items: center;
}
.light .sticky-wrapper {
  background-color: #c5dff2;
  color: black;
}
.dark .sticky-wrapper {
  background-color: #000f2b;
  color: white;
}

.sticky {
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  z-index: 65535;
  opacity: 0.9;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
}

.sticky-wrapper {
  position: relative;
  height: 40px;
}

.navItems {
  margin: 0.5rem 0 0 0;
  padding: 0 8px;
  border-bottom: transparent solid 2px;
  font-size: 50;
}

.navItems:hover {
  cursor: pointer;
  /*text-decoration: none !important;*/
}

.navBar a {
  text-decoration: none !important;
}

.navBarStuck a {
  text-decoration: none !important;
}

.navCurrentPage, .navCurrentPageSticky {
  border-bottom: #1277d3 solid 2px;
  color: #1277d3;
}

.citationLink {
  text-decoration: underline;
} .light .citationLink {
  color: blue;
} .dark .citationLink {
  color: #1277d3;
}

.flightSearch {
  width: 98%;
  border-width: 2.5px;
  border-color: rgb(186, 217, 229);
  margin-bottom: 15px;
}

.tableRows {
  border: 1px solid #87ceeb;
}

.tableRows:nth-child(even) {
  background-color: #f2f2f2;
}

.tableData {
  border-right: 1px solid #87ceeb;
}

.customStateSelect {
  border-radius: 4px;
  border: 2px solid #87ceeb;
  background-color: white;
}

.buttonStyles {
  background-color: white;
  color: black;
  border: 2px solid #87ceeb;
  transition-duration: 0.4s;
  border-radius: 4px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin: 1px;
}

.buttonStyles:hover {
  background-color: #87ceeb;
  color: white;
}

.pagination {
  justify-content: center;
  margin-top: 1rem;
}

.alignStyles {
  margin: 0;
  padding: 1em;
}

.hoverButton {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
} .light .hoverButton {
  color: #666;
} .dark .hoverButton {
  color: #bbb;
}

.dataSource {
  padding-left: 0.2rem;
  margin-top: -0.05rem;
  cursor: pointer;
}
.dailyIncrease {
  font-size: 90%;
}

.selection-grid {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 15px;
}

.slogan {
  text-align: center;
  margin-top: -5px;
  margin-bottom: -10px;
  font-size: 1.1rem;
}

.selection-grid {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 15px;
}

.slogan {
  text-align: center;
  margin-top: -5px;
  margin-bottom: -10px;
}

.dropdown-item {
  padding-left: 1rem;
}

@media only screen and (max-width: 900px) {
    .desktop-only {
        display: none;
    }
}

.mapboxgl-popup-close-button {
  margin-top: 1px;
  font-size: 3.5em;
  margin-right: -7px;
  color: gray;
}
.mapboxgl-popup.mapboxgl-popup-anchor-top {
  width: 80vw !important;
  max-width: 350px !important;
}


.MuiButton-root {
  text-transform: none !important;
}